// $primary: #b33771;
// $enable-rounded: false;
//$warning: hsla(13, 60%, 50%,.75) ;
//$warning: #9D69A3;
$warning:  rgb(8, 100, 100);
$primary: #003554;
$info: #128491;
$danger: #bb4141;
// $danger: rgba(240, 9, 1, 0.85);
$green: rgb(8, 100, 43);
$success: #14924d;
$light: rgb(247, 247, 247);
$white: rgb(255, 255, 255);
$dark: rgba(0, 0, 0,.90);
border-white{
    border: 1px solid rgba(104, 104, 104, 0.9) !important;
}
// Leave this import at the bottom
@import "node_modules/bootstrap/scss/bootstrap";
