.landing-page {
    background: white !important;
    color: black !important;
    min-height: 100vh;
}

.bg-nav-menu {
    background: rgba(32, 41, 43, 0.918);
}
.use-border{
    border: 1px solid rgba(23,66,73, 0.801);
}
.landing-heading {
    font-size: 3rem;
    font-weight: 400;
}

.landing-subheading {
    font-size: 2.7rem;
    font-weight: 400;
}


.text-landing {
    color: #165A64 !important;
}

.landing-background {
    /* background: #1D3235 !important; */
    background: rgb(29,50,53);
    background: linear-gradient(90deg, rgba(29,50,53,1) 0%, rgba(23,66,73,1) 47%, rgba(25,74,82,1) 58%, rgba(29,49,52,1) 100%, rgba(25,44,47,1) 100%, rgba(25,43,46,1) 100%);
}
.landing-background-glass {
    /* background: #1D3235 !important; */
    color: white;
    background: rgb(29,50,53);
    background: linear-gradient(90deg, rgba(29,50,53,.85) 0%, rgba(23,66,73,.85) 47%, rgba(25,74,82,.85) 58%, rgba(29,49,52,.85) 100%, rgba(25,44,47,.86) 100%, rgba(25,43,46,.85) 100%);
}

.landing-button {
    background: #1D3235;
    border-color: #192b2e;
    color: white;
    margin-top: 0.05em;
    margin-bottom: 0.05em;
}

.landing-button:hover {
    background: #165A64;
    color: white;
    box-shadow:rgba(0, 0, 0, 0.336) 0px 0px 10px 0px;
    margin-top: 0em;
    margin-bottom: 0.1em;
}
.landing-button-rev:hover {
    background: #1D3235;
    border-color: #192b2e;
    color: white;
    margin-top: 0.05em;
    margin-bottom: 0.05em;
}

.landing-button-rev {
    background: #165A64;
    color: white;
    box-shadow:rgba(0, 0, 0, 0.336) 0px 0px 10px 0px;
    margin-top: 0em;
    margin-bottom: 0.1em;
}

.landing-button-inverse {
    background: #165A64;
}
.landing-button-inverse:hover {
    background: #1D3235;
}


.details {
    margin: 2em 0em 0em 0.5em;
    font-size: 1.2em;
    font-weight: 300;
}

.summary {
    font-weight: bold;
    margin-left: -0.5em;
    margin-bottom: 0.75em;
}